import React from "react"
import Header from "../components/header/header"
import Footer from "../components/footer/Footer"
import "./404.scss"
const NotFoundPage = () => (
  <>
    <div className="single-header">
      <Header></Header>
    </div>

    <article className="single-article single-404">
      <div className="container">
        <h1 className="text-container single-article__title">
          Det virker som denne siden ikke er tilgjengelig lenger.
        </h1>
        <div className="single-article__content text-container" />
        Det ser ut til at ingenting ble funnet på dette stedet.
      </div>
    </article>
    <Footer></Footer>
  </>
)

export default NotFoundPage
