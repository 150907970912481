import React from "react"
import FooterBar from "./footerBar"
import { useStaticQuery, graphql } from "gatsby"

import SubscribeForm from "./subscribeForm"
import BackTop from "./BackTop"
//Images

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        themeOptions {
          themeSettings {
            subscribeEmail
            subscribeTitle
            subscribeText
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <footer className="main_footer">
      <BackTop />
      <SubscribeForm
        title={data.wp.themeOptions.themeSettings.subscribeTitle}
        text={data.wp.themeOptions.themeSettings.subscribeText}
        mail={data.wp.themeOptions.themeSettings.subscribeEmail}
      ></SubscribeForm>
      <FooterBar></FooterBar>
    </footer>
  )
}

export default Footer
