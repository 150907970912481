import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.scss"
//Images
import logo from "../../images/logo.svg"

const Header = ({ siteTitle }) => (
  <>
    <header className="main_header">
      <div className="container">
        <Link className="logo" to="/">
          <img src={logo} alt="SRHR" />
        </Link>
        <Link className="nav-link" to="/"></Link>
      </div>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
