import React from "react"
import "./footerBar.scss"
//Images

const FooterBar = () => (
  <div className="footer-bar">
    © Nasjonalt SRHR-nettverk {new Date().getFullYear()}
  </div>
)

export default FooterBar
