import React, { useState, useEffect } from "react"
import "./BackTop.scss"

const BackTop = props => {
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop)
      window.scrollTo(0, c - c / 8)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
  })

  return (
    <button
      onClick={scrollToTop}
      style={{ display: showScroll ? "block" : "none" }}
      className="back-top"
    ></button>
  )
}

export default BackTop
