import React from "react"
import PropTypes from "prop-types"
import "./subscribeForm.scss"

const SubscribeForm = props => {
  return (
    <div className="subscribe-form">
      <div className="container">
        <div className="subscribe-form__content">
          <h3>{props.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
        </div>
        <div className="subscribe-form__wrapper">
          <div className="input-group">
            <a href={"mailto:" + props.mail} className="mailbtn">
              SEND
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
SubscribeForm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  mail: PropTypes.string,
}
export default SubscribeForm
